import React, { useEffect, useContext, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
// import { SearchPageSearch } from "../components/search/search-page-search";
import SearchIndex from "../components/search/search-index";

// Utils
import { FormatProductTitle } from "../components/utils/format-product-title";

const Page = styled.div`
  padding: 190px 0 20px 0;

  @media (max-width: 920px) {
    padding: 115px 0;
  }

  @media (max-width: 600px) {
    padding: 100px 0;
  }

  & .active-search-image {
    grid-column: 2 / 6;

    & img {
      width: min((100vh - 360px), 550px);
      max-width: 550px;

      @media (max-width: 1260px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  & .content-container {
    max-width: 770px;

    grid-column: 7 / 13;

    @media (max-width: 1230px) {
      grid-column: 6 / 13;
    }

    @media (max-width: 1050px) {
      grid-column: 5 / 13;
    }

    @media (max-width: 960px) {
      grid-column: 1 / 13;
    }
  }

  & h1 {
    font-size: 14px;
    line-height: 30px;

    letter-spacing: 0.03em;

    margin: 0 0 3em 0;

    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 13px;
    }
  }

  & p.result-type {
    font-size: 14px;
    line-height: 30px;

    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 13px;
      letter-spacing: 0.02em;
    }
  }

  & p.result-title {
    font-size: 20px;
    line-height: 30px;

    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 13px;
      letter-spacing: 0.02em;
    }
  }

  & p:empty {
    display: block;
    margin: 2em 0 0 0;
  }

  & strong {
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    font-weight: 300;

    margin: 0;

    @media (max-width: 600px) {
      font-size: 7px;
      line-height: 13px;
      letter-spacing: 0.02em;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
`;

const NoResults = styled.div`
  & p {
    margin: 0;

    &:last-of-type {
      margin: 0 0 0 -5px;
    }
  }

  & a {
    & span {
      opacity: 0;
    }

    &:hover {
      & span {
        opacity: 1;
      }
    }
  }
`;

const SingleSearchResult = styled.div`
  &.product {
    & .line {
      display: grid;
      grid-template-columns: 100px auto;
      grid-column-gap: 20px;
      align-items: baseline;

      & p {
        margin: 0;
        text-transform: lowercase;
      }
    }
  }
`;

const SearchPage = ({ location }) => {
  const [searchResults, updateSearchResults] = useState([]);
  const [activeSearchResult, setActiveSearchResult] = useState(null);

  useEffect(() => {
    if (
      location !== null &&
      location.state !== null &&
      location.state !== undefined
    ) {
      updateSearchResults(location.state.searchResults);
    } else {
      return null;
    }
  }, [location]);

  if (
    location !== null &&
    location.state !== null &&
    location.state !== undefined
  ) {
    return (
      <>
        <Page>
          <Grid>
            {location !== null &&
            location.state !== null &&
            location.state !== undefined ? (
              <>
                <Helmet
                  title={`Search Results for '${location.state.searchQuery}' | Metamorphoses`}
                  meta={[
                    {
                      name: "og:title",
                      content: `Search Results for '${location.state.searchQuery}' | Metamorphoses`,
                    },
                  ]}
                />

                <div className="active-search-image">
                  {searchResults !== null &&
                    searchResults !== undefined &&
                    activeSearchResult !== null && (
                      <>
                        {activeSearchResult.type === "object" && (
                          <>
                            {activeSearchResult.data.shopify_product.image
                              .src !== undefined && (
                              <img
                                src={
                                  activeSearchResult.data.shopify_product.image
                                    .src
                                }
                                alt={
                                  activeSearchResult.data.shopify_product.title
                                }
                                loading={`lazy`}
                              />
                            )}
                          </>
                        )}

                        {(activeSearchResult.type === "conversation" ||
                          activeSearchResult.type === "collaboration") && (
                          <>
                            {activeSearchResult.data.thumbnail.fluid !==
                              undefined && (
                              <img
                                srcSet={
                                  activeSearchResult.data.thumbnail.fluid.srcSet
                                }
                                src={
                                  activeSearchResult.data.thumbnail.fluid.src
                                }
                                alt={activeSearchResult.data.thumbnail.alt}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                </div>

                <div className="content-container">
                  <h1>search results for ‘{location.state.searchQuery}’</h1>

                  {searchResults !== null &&
                    searchResults !== undefined &&
                    searchResults.map((result, outerIndex) => {
                      if (result.type === "object") {
                        return (
                          <SingleSearchResult
                            key={`single_product_${result.id}_${outerIndex}`}
                            className="product"
                            onMouseEnter={() => setActiveSearchResult(result)}
                            onMouseLeave={() => setActiveSearchResult(null)}
                          >
                            <Link to={`/objects/${result.uid}/`}>
                              <div className="product">
                                <div className="line">
                                  <p className="result-type">shop</p>
                                  <p className="result-title">
                                    {
                                      FormatProductTitle(
                                        result.data.shopify_product.title
                                      )[0]
                                    }
                                    {", "}
                                    {
                                      FormatProductTitle(
                                        result.data.shopify_product.title
                                      )[1]
                                    }
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </SingleSearchResult>
                        );
                      }

                      if (result.type === "conversation") {
                        return (
                          <SingleSearchResult
                            key={`single_conversation_${result.id}_${outerIndex}`}
                            className="product"
                            onMouseEnter={() => setActiveSearchResult(result)}
                            onMouseLeave={() => setActiveSearchResult(null)}
                          >
                            <Link to={result.uid}>
                              <div className="product">
                                <div className="line">
                                  <p className="result-type">conversation</p>
                                  <p className="result-title">
                                    {result.data.title.text}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </SingleSearchResult>
                        );
                      }

                      if (result.type === "collaboration") {
                        return (
                          <SingleSearchResult
                            key={`single_conversation_${result.id}_${outerIndex}`}
                            className="product"
                            onMouseEnter={() => setActiveSearchResult(result)}
                            onMouseLeave={() => setActiveSearchResult(null)}
                          >
                            <Link to={`/collaborations/${result.uid}`}>
                              <div className="product">
                                <div className="line">
                                  <p className="result-type">collaboration</p>
                                  <p className="result-title">
                                    {result.data.maker.document !== null && (
                                      <>
                                        {`${result.data.maker.document.data.name.text}, `}
                                      </>
                                    )}

                                    {result.data.title.text}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </SingleSearchResult>
                        );
                      }
                    })}

                  {location.state.searchResults.length >= 1 && (
                    <SearchIndex location={location} type={"search-page"} />
                  )}

                  {location.state.searchResults.length === 0 && (
                    <div className="content-container">
                      <NoResults>
                        <p>
                          sorry, no results found, please try again or{" "}
                          <Link to={`/shop/`}>
                            {/* <span>(</span> */}
                            browse our shop
                            {/* <span>)</span> */}
                          </Link>
                        </p>
                      </NoResults>

                      <SearchIndex location={location} type={"search-page"} />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="content-container">
                <NoResults>
                  <p>
                    sorry, no results found, please try again or{" "}
                    <Link to={`/shop/`}>
                      <span>(</span>browse our shop<span>)</span>
                    </Link>
                  </p>
                </NoResults>

                <SearchIndex location={location} type={"search-page"} />
              </div>
            )}
          </Grid>
        </Page>
      </>
    );
  } else {
    return (
      <>
        <Helmet
          title={`Search Results | Metamorphoses`}
          meta={[
            {
              name: "og:title",
              content: `Search Results | Metamorphoses`,
            },
          ]}
        />
        <Page>
          <Grid>
            <div className="content-container">
              <NoResults>
                <p>
                  sorry, no results found, please try again or{" "}
                  <Link to={`/shop/`}>
                    <span>(</span>browse our shop<span>)</span>
                  </Link>
                </p>
              </NoResults>

              <SearchIndex location={location} type={"search-page"} />
            </div>
          </Grid>
        </Page>
      </>
    );
  }
};

export default SearchPage;
